import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/components/navbar/Header.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThirdwebProvider"] */ "/usr/src/app/app/thirdweb.ts");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
