// app/components/ConnectWallet.tsx
"use client"; // Specifying this as a client component

import { ConnectButton, useActiveAccount } from "thirdweb/react";
import { inAppWallet, createWallet } from "thirdweb/wallets";
import { CHAIN, client } from "@/utils/constants";
import { useEffect } from "react";

// Define custom wallets array with inAppWallet and third-party wallets
const wallets = [
  inAppWallet({
    auth: {
      options: [
        "google",
        "email",
        "passkey",
        "phone",
        "facebook",
        "apple",
      ],
    },
  }),
  createWallet("io.metamask"),
  createWallet("app.phantom"),
  createWallet("com.robinhood.wallet"),
  createWallet("org.uniswap"),
  createWallet("com.trustwallet.app"),
];

// Define the custom theme for the ConnectButton
const customTheme = {
  colors: {
    accentButtonBg: "#ff7f50",
    accentButtonText: "#ffffff",
    accentText: "#d2691e",
    borderColor: "#d3d3d3",
    connectedButtonBg: "#ffffff",
    connectedButtonBgHover: "#FAF3E0",
    danger: "#dc3545",
    inputAutofillBg: "#f8f9fa",
    modalBg: "#f0f0f5",
    modalOverlayBg: "rgba(0, 0, 0, 0.5)",
    primaryButtonBg: "#1877F2",
    primaryButtonText: "#FFFFFF",
    primaryText: "#333333",
    scrollbarBg: "#dcdcdc",
    secondaryButtonBg: "#e9ecef",
    secondaryButtonHoverBg: "#d4d9de",
    secondaryButtonText: "#495057",
    secondaryIconColor: "#adb5bd",
    secondaryIconHoverBg: "#ced4da",
    secondaryIconHoverColor: "#ffffff",
    secondaryText: "#666666",
    selectedTextBg: "#e0e0e0",
    selectedTextColor: "#333333",
    separatorLine: "#dee2e6",
    skeletonBg: "#e0e0e0",
    success: "#28a745",
    tertiaryBg: "#f8f9fa",
    tooltipBg: "#333333",
    tooltipText: "#ffffff",
  },
  fontFamily: "GeistVf, Arial, sans-serif",
  type: "light" as const,
};

export default function ConnectWallet() {
  const account = useActiveAccount();

  // Function to save wallet address to the database
  const saveAddressToDatabase = async (address: string) => {
    try {
      const response = await fetch("/api/user/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response:", errorText);
        throw new Error("Failed to save address to the database");
      }
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };

  // Save wallet address on connection
  useEffect(() => {
    if (account?.address) {
      saveAddressToDatabase(account.address);
    }
  }, [account?.address]);

  return (
    <ConnectButton
      chain={CHAIN}
      client={client}
      wallets={wallets} // Pass the custom wallets array
      appMetadata={{
        name: "YongSanCats",
        url: "https://yongsancats.com",
      }}
      theme={customTheme} // Apply custom theme
      connectButton={{
        label: "Sign-In",
        style: {
          borderRadius: "30px",
          padding: "1px 2px",
          margin: "0px",
        },
      }}
      connectModal={{ size: "wide" }} // Set connect modal size to wide
    />
  );
}
