// app/components/Logo.tsx
import Image from 'next/image';
import Link from 'next/link';

export default function Logo() {
  return (
    <Link href="/">
      <div aria-label="Home">
        <Image
          src="/logo/YongSanCats_red.svg"
          alt="YongsanCats Logo"
          width={150} // Adjust width as needed
          height={50} // Adjust height as needed
          className="h-auto w-auto"
        />
      </div>
    </Link>
  );
}
