import { createThirdwebClient, defineChain, } from 'thirdweb';
import { getContract } from 'thirdweb';


// NEXT_PUBLIC_CLIENT_ID="186bcb4fee2797ec9878b8849356298f" 
// from .env

export const client = createThirdwebClient(
{
    clientId : process.env.NEXT_PUBLIC_CLIENT_ID as string,
})

//Base chain
// export const CHAIN = defineChain(8453);    

//Base sepolia testnet
export const CHAIN = defineChain(84532);


const CONTRACT_ADDRESS = "0x3cA07ca8D5cb17f03af4FFb246d57fAA6aE6acE9";

export const contract = getContract({
    client : client,
    address : CONTRACT_ADDRESS,
    chain : CHAIN,
})

// export const NFTDROP_ADDRESS = "0x77703d0359BE440F4F2C5f4C4f9eC5E8e8A9A3e8"

